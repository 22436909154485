import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import Testimonial from '../../components/Testimonial';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img src="/img/client-logos/3advance.png" alt="3Advance" className="client-logo" />
    <h3>{`The Client`}</h3>
    <p><strong parentName="p">{`3Advance`}</strong>{` is a software development firm, headquartered in Washington DC, who specialise in building apps for startups.`}</p>
    <h3>{`The Problem`}</h3>
    <p>{`3Advance’s business model is built around delivering value fast for their clients. This means iterating quickly on front and backends while still maintaining high quality. The development team had been using a .NET stack hosted on Microsoft Azure for their backend APIs, but were hitting problems with this stack in particular around operational issues such as performance and scalability. The team are application developers—not specialised infrastructure or operations engineers—and so spending time resolving infrastructure issues in areas which weren’t their core expertise was time not spent on delivering value for their clients.`}</p>
    <h3>{`The Solution`}</h3>
    <p>{`I was engaged with the remit of leading the transformation of the 3Advance backend development team to a new stack based around AWS serverless.
We piloted two client projects which used the Serverless Framework, API Gateway, Lambda, S3, SQS, Cognito and DynamoDB.`}</p>
    <p>{`After the successful launch of the pilots, the decision was made that all new client projects would use AWS serverless for their backend.
After each client project launch, developers grew in confidence, gaining experience with several new AWS services and becoming productive with new concepts and development practices such as event-driven architecture, Infrastructure-as-Code, automated integration `}{`&`}{` E2E tests and continuous deployment.`}</p>
    <h3>{`The Results`}</h3>
    <ul>
      <li parentName="ul">{`Shipped over 20 client apps in a 2-year period using the new AWS serverless stack at the backend`}</li>
      <li parentName="ul">{`Developers are fully self-sufficient in the new stack, both with the development and operational aspects`}</li>
      <li parentName="ul">{`Performance issues are much less common now`}</li>
      <li parentName="ul">{`The team has templates, libraries, CI/CD pipeline and documented procedures in place for efficiently rolling out a new multi-account AWS setup and codebase for all new client projects`}</li>
      <li parentName="ul">{`Cloud bills for 3Advance clients are small and directly tied with their app’s usage levels, due to the serverless pay-per-use pricing model`}</li>
      <li parentName="ul">{`Client projects have used a wide range of AWS services including AppSync, API Gateway, Lambda, S3, CloudFront, Cognito, DynamoDB, EventBridge, SQS, SNS, Step Functions, Amazon Connect and many more`}</li>
    </ul>
    <Testimonial authorName="Darren Gibney" authorTitle="Partner &amp; CTO at 3Advance" authorPhotoUrl="/img/client-logos/darren-gibney.jpg" mdxType="Testimonial">
  <div>
    <p>
      From Paul’s blog posts it was clear we were bringing someone in who could
      bring us from zero to hero.
    </p>
    <p>
      Once we started working with him, we got what we expected and more. Things
      don’t stay static in tech and this was especially true of Serverless - AWS
      is continually improving and updating existing services and adding new
      ones. We didn’t want to be fighting the technology, we needed to deliver
      value for our projects and Paul helped us keep that balance.
    </p>
    <p>
      Paul’s focus on developer experience was probably the thing I most liked
      about his work with us. We frequently needed to onboard new team members
      and handoff projects to internal teams and this was key to long term
      success of projects. He raised the bar for our team, in many ways we’ve
      surpassed our previous stack in terms of IaC, CI/CD and testing.
    </p>
    <p>
      Paul knows his stuff and instantly had respect of all team members and
      leadership. Very diplomatic, change is not easy for all. Was professional
      at all times. Our team literally went from nothing on AWS and Serverless
      to feeling like there is not much we can’t do.
    </p>
    <p>Looking forward to our next engagement with him.</p>
  </div>
    </Testimonial>
    <h2>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://3advance.com"
        }}>{`3Advance website`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      